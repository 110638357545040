<template>  
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.2188 5.0625C13.081 6.92121 11.6719 8.4375 10.125 8.4375C8.57815 8.4375 7.16663 6.92156 7.03128 5.0625C6.89065 3.12891 8.26175 1.6875 10.125 1.6875C11.9883 1.6875 13.3594 3.16406 13.2188 5.0625Z" :stroke="color" stroke-width="1.5" stroke-linecap="round" fill="none" stroke-linejoin="round"/>
    <path d="M10.125 10.6875C7.06643 10.6875 3.96213 12.375 3.38768 15.5602C3.31736 15.9441 3.53568 16.3125 3.93787 16.3125H16.3125C16.7147 16.3125 16.932 15.9441 16.8627 15.5602C16.2879 12.375 13.1836 10.6875 10.125 10.6875Z" :stroke="color" stroke-width="1.5" fill="none" stroke-miterlimit="10"/>
    <path d="M3.09375 6.1875V10.125" :stroke="color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M5.0625 8.15625H1.125" :stroke="color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>

export default {
  name: 'UserAddIcon',
  props: {
    color: {
      type: String,
      default: 'white'
    }
  }
}
</script>