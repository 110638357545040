<template>
  <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.749853 -0.000158539C0.550942 -0.000158542 0.360174 0.0788591 0.219523 0.219512C0.078871 0.360164 -0.000146682 0.55093 -0.000146684 0.749842L-0.000146762 7.24984C-0.000146773 8.2444 0.39494 9.19823 1.0982 9.90149C1.80146 10.6048 2.75529 10.9998 3.74985 10.9998L17.4149 10.9998L13.6949 14.7198C13.568 14.847 13.4909 15.0154 13.4777 15.1945C13.4645 15.3736 13.516 15.5515 13.6229 15.6958L13.6949 15.7798C13.8219 15.9071 13.9905 15.9845 14.1698 15.9979C14.3491 16.0113 14.5273 15.9598 14.6719 15.8528L14.7559 15.7798L19.7559 10.7798C19.8829 10.6529 19.9601 10.4845 19.9735 10.3054C19.9869 10.1263 19.9356 9.94831 19.8289 9.80384L19.7559 9.71984L14.7559 4.71984C14.6219 4.58689 14.4426 4.50935 14.254 4.5027C14.0653 4.49606 13.8811 4.5608 13.7381 4.684C13.5951 4.80721 13.5038 4.97981 13.4824 5.16736C13.4611 5.35492 13.5112 5.54363 13.6229 5.69584L13.6949 5.77984L17.4149 9.49984L3.74985 9.49984C3.1799 9.49966 2.63125 9.28318 2.21474 8.89413C1.79822 8.50508 1.54486 7.97246 1.50585 7.40384L1.49985 7.24984L1.49985 0.749842C1.49985 0.55093 1.42084 0.360164 1.28018 0.219512C1.13953 0.0788591 0.948765 -0.000158537 0.749853 -0.000158539Z" :fill="color"/>
  </svg>

</template>

<script>
export default {
  name: 'SubArrowIcon',
  props: {
    color: {
      type: String,
      default: '#E4E4E4'
    }
  }
}
</script>

<style>

</style>