<template>
  <div class="tnd-user-card is-flex is-align-items-center is-justify-content-center is-flex-wrap-wrap">
      <div class="tnd-user-card__avatar" :style="`background-image:url(${userInfo.user_image})`"></div>
      <div class="tnd-user-card__info">
        <p class="info-text name is-size-4">{{userInfo.name}}</p>
        <p class="info-text email is-size-6"><mail-icon width="13" height="10"/><span>{{userInfo.email}}</span></p>
        <p class="info-text phone is-size-6"><phone-icon/><span>{{userInfo.phone || '-'}}</span></p>
      </div>
  </div>
</template>

<script>
import MailIcon from '../icons/MailIcon.vue'
import PhoneIcon from '../icons/PhoneIcon.vue'
export default {
  components: {
    PhoneIcon,
    MailIcon
  },
  name: 'TndUserCard',
  props: {
    userInfo: {
      type: Object,
      default: () => ({})
    }
  },
  data:() => ({

  })
}
</script>

<style lang="scss" scoped>
@import '../../assets/variables';
.tnd-user-card {
  &__avatar {
    height: 7.5rem;
    width: 7.5rem;
    box-shadow: 5px 10px 50px rgba(175, 175, 175, 0.25);
    border-radius: 10px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  &__info {
    padding-left: 2.18rem;

    .info-text {
      display: flex;
      align-items: center;
      color: $font-default;

      & + .info-text {
        margin-top: 8px;
      }

      span {
        padding-left: 0.6rem;
      }
    }

    @media screen and (max-width: 768px) {
      padding-left: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

  }
}
</style>