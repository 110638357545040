<template>
  <div class="columns tender-files">
      <div class="column">
          <div class="tender-files__title">Формы договора</div>
          <tnd-file 
            v-for="(deal, index) of deals" 
            :file="deal"
            action="download" 
            @action="download"
            :key="index"/>
      </div>
      <div class="column">
          <div class="tender-files__title">Исходные данные</div>
          <tnd-file 
            v-for="(data, index) of initialData" 
            :file="data"
            action="download" 
            @action="download"
            :key="index"/>
      </div>
  </div>
</template>

<script>
import TndFile from '../components/common/TndFile.vue'
export default {
  components: { TndFile },
  name: 'TenderFiles',
  props: {
    files: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    deals() {
      return this.files.filter(file => file.doc_template_id === 2)
    },
    initialData() {
      return this.files.filter(file => file.doc_template_id === 3)
    }
  },
  methods: {
    download(file) {
      this.$store.dispatch('files/download', file.link)
    }
  }
}
</script>

<style lang="scss">
@import '../assets/variables.scss';
  .tender-files{
    &__title {
      color: $font-default;
      font-size: 1.375rem;
      margin-bottom: 1.9rem;
    }
  }
</style>