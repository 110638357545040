<template>
  <div class="details-positions">
    <div class="details-positions__title">Позиции тендера</div>
    <div class="columns">
      <div class="column">
        <tnd-pre-price value="1 855 000 232 руб"/>
      </div>
      <div class="column is-flex is-justify-content-end is-align-items-end">
        <a class="details-positions__oferta-link is-flex is-align-items-center" href="">Распечатать оферту <printer-icon class="ml-2" color="#6F6F6F"/></a>
      </div>
    </div>
    <tnd-table :data="data" :columns="columns" v-slot="{props, column}">
        <div class="is-flex is-align-items-center" v-if="column.type === 'description'">
          <div class="icon mr-5" v-if="props.row[column.field].child"><sub-arrow-icon/></div>
          <div class="text">
            <p class="row-title">{{props.row[column.field].title}}</p>
            <p class="row-description">{{props.row[column.field].description}}</p>
          </div>
        </div>
    </tnd-table>
  </div>
</template>

<script>
import TndPrePrice from '../components/common/TndPrePrice.vue'
import TndTable from '../components/common/TndTable.vue'
import PrinterIcon from '../components/icons/PrinterIcon.vue'
import SubArrowIcon from '../components/icons/SubArrowIcon.vue'
export default {
  components: { TndPrePrice, PrinterIcon, TndTable, SubArrowIcon },
  name: 'DetailsPositions',
  props: {
    data: {
      type: Array,
      default: () => []
    },
    columns: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style lang="scss">
.details-positions {
  
  &__title {
      font-size: 1.375rem;
      margin-bottom: 1.9rem;
    }
}
</style>