<template>
  <tnd-table-list :data="tenderPreview"/>
</template>

<script>
import TndTableList from '../components/common/TndTableList.vue'
import { filterSelects } from '../home/TenderListFull/TenderListData'
export default {
  components: { TndTableList },
  name: 'TenderInfo',
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    
  }),
  computed: {
    tenderPreview(){
      if(!Object.keys(this.data).length) return []
      const status = filterSelects.status.find(status => status.id === this.data.extanded_info.current_status_id)
      const tenderProject = filterSelects.project.find(project => project.id === this.data.extanded_info.project_id)
      const tenderCategory = filterSelects.categories.find(category => category.id === this.data.extanded_info.category_id)
      const tenderObject = '' //нет в моке
      const tenderType = '' //нет в моке
      return [
        {
          name: 'Информация о тендере',
          params: [
            {
              name: 'НОМЕР ТЕНДЕРА:',
              value: this.data.id,
              
            },
            {
              name: 'текущий статус:',
              value: status.title,
              class: status.bgColor,
              type: 'status'
            },
            {
              name: 'Вид тендера:',
              value: this.data.extanded_info.tender_scenario_id,
            },
            {
              name: 'ДАТА НАЧАЛА ПРОВЕДЕНИЯ:',
              value: this.data.extanded_info.dt_start ? this.$moment(this.data.extanded_info.dt_start).format('DD MMMM YYYY, HH:mm') : '-',
            },
            {
              name: 'ДАТА окончания ПРОВЕДЕНИЯ:',
              value: this.data.extanded_info.dt_end ? this.$moment(this.data.extanded_info.dt_end).format('DD MMMM YYYY, HH:mm') : '-',
            },
            {
              name: 'КРАТКОЕ ОПИСАНИЕ:',
              value: this.data.extanded_info.description_short,
            },
            {
              name: 'ОПИСАНИЕ:',
              type: 'tag',
              value: this.data.extanded_info.description_full,
            },
            {
              name: 'ОТВЕТСТВЕННЫЙ СОТРУДНИК:',
              nameColor: '#FAB72A',
              value: this.data.author.name
            },
            {
              name: 'Категория:',
              value: tenderCategory.title,
            },
            {
              name: 'Проект:',
              value: tenderProject.title,
            },
            {
              name: 'Обьект:',
              value: this.data.extanded_info.object_id,
            },
            {
              name: 'дата начала исполнения:',
              value: this.data.extanded_info.dt_plan_start ? this.$moment(this.data.extanded_info.dt_plan_start).format('DD MMMM YYYY, HH:mm') : '-',
            },
            {
              name: 'дата окончания исполнения:',
              value: this.data.extanded_info.dt_plan_end ? this.$moment(this.data.extanded_info.dt_plan_end).format('DD MMMM YYYY, HH:mm') : '-',
            },
            {
              name: 'СРОК ИСПОЛНЕНИЯ, дней:',
              value: this.data.extanded_info.period_execution,
            },
            {
              name: 'контакт по ТЕХНИЧЕСКИМ вопросаМ:',
              nameColor: '#FAB72A',
              value: this.data.author.name,
            }
          ]
        }
      ]
    } 
  }
  }
</script>

<style>

</style>